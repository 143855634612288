html {
  background-color: #fff !important;
}

#submit-btn {
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 0.5em 1.2em;
  font-size: 15px;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: 0.5s ease;
  background-color: #4e46e4;
  color: white;
  width: 100%;
}

#submit-btn:hover,
#submit-btn:focus {
  outline: none;
  color: black;
  border-color: #4e46e4;
  background-color: #fff;
  box-shadow: 0 0 0 5px #4e46e450;
}

.input {
  width: 100%;
  height: 45px;
  line-height: 30px;
  padding: 0 1rem;
  padding-left: 1rem;
  border: 2px solid #4e46e4;
  border-radius: 10px;
  outline: none;
  background-color: #f5faff;
  color: #0d0c22;
  transition: 0.5s ease;
  font-size: 13px;
}

.input::placeholder {
  color: #8698b1;
}

.input:focus,
.input:hover {
  outline: none;
  border-color: #4e46e4;
  background-color: #fff;
  box-shadow: 0 0 0 5px #4e46e450;
}
.main {
  width: 95%;
  margin: 0 auto;
  max-width: 350px;
  padding: 2rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 1rem;
  text-align: left;
}

.email-not-found > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.email-not-found > div > button {
  padding: 10px 15px !important;
  font-size: 15px !important;
  width: 100%;
}

#tableContainer tbody tr:last-child {
  border-radius: 20px !important;
}
#tableContainer th,
td {
  text-align: center !important;
}
#tableContainer th {
  background-color: #646cff;
  color: white !important;
  font-weight: 500 !important;
}
#tableContainer td {
  font-size: 12px;
  border: 1px solid #646cff;
}
